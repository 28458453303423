<script setup>
// import _ from 'lodash'
import {
  // nextTick,
  computed,
  ref,
  reactive,
  // onMounted
} from 'vue'

import { useWindowSize } from 'vue-window-size'

const props = defineProps(['place', 'offline'])
// const emit = defineEmits( [] )

const { width, height } = useWindowSize()

const onlineOrderStarted = ref(false)

const location = computed(() => {
  if (props.place == 'spg') {
    return {
      name: 'Greensboro',
      address1: '4129 Spring Garden St.',
      address2: 'Greensboro, NC 27407',
      phone: '336-542-0156',
      image_url: '/images/springgardenst.jpg',
      online_order_link: '',
    }
  } else if (props.place == 'bkm') {
    return {
      name: 'Winston-Salem',
      address1: '3439 Burke Mill Rd.',
      address2: 'Winston-Salem, NC 27103',
      phone: '336-331-3893',
      image_url: '/images/burkemillrd.png',
      online_order_link: '',
    }
  }
})

function callinOrderStart() {
  if (props.place == 'spg') {
    window.location.href = 'tel:+13365420156'
  } else if (props.place == 'bkm') {
    window.location.href = 'tel:+13363313893'
  }
}

function clickMapLink() {
  if (props.place == 'spg') {
    window.open('geo:36.06796084472275, -79.8683056684836', '_blank')
  } else if (props.place == 'bkm') {
    window.open('geo:36.06796084472275, -79.8683056684836', '_blank')
  }
}

function onlineOrderStart() {
  onlineOrderStarted.value = true

  setTimeout(() => {
    onlineOrderStarted.value = false
  }, 2000)

  if (props.place == 'spg') {
    window.location.href =
      'https://direct.chownow.com/direct/29859/locations/44182'
  } else if (props.place == 'bkm') {
    window.location.href =
      'https://www.toasttab.com/little-aris-japanese-kitchen-3439-burke-mill-rd-nc-27101'
  }
}
</script>

<template>
  <div id="location-container">
    <div id="address">
      <div>
        <div>
          <div id="address-header">
            {{ location.name }}
          </div>
          {{ location.address1 }}<br />
          {{ location.address2 }}<br /><br />
          {{ location.phone }}
        </div>

        <div>
          <div
            id="call-button"
            @click="callinOrderStart"
            v-if="width < 800">
            Call
          </div>
          <div
            id="order"
            v-if="props.offline"
            @click="onlineOrderStart">
            <span v-if="!onlineOrderStarted">Online Order</span>
            <span
              v-else
              class="ellipsis-anim"
              ><span>.</span><span>.</span><span>.</span></span
            >
          </div>
        </div>
        <!-- <div id="call-button" @click="clickMapLink" v-if="width < 800">Map</div> -->
      </div>
    </div>
    <div id="image-container">
      <img
        id="image"
        :src="location.image_url" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#location-container {
  box-shadow: 0 0 10px #000;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  margin-bottom: 50px;

  @media screen and (max-width: 480px) {
    margin-bottom: 0;
    border-top: black 3px solid;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    max-height: auto;
  }

  @media screen and (min-width: 801px) {
    max-height: 300px;
    width: 680px;
  }
}

#image-container {
  height: 100%;
  max-width: 450px;

  @media screen and (max-width: 480px) {
    max-width: 480px;
  }

  margin-bottom: -5px;
}

#image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 480px) {
    border-radius: 0;
    width: 100vw;
  }

  @media screen and (min-width: 481px) and (max-width: 800px) {
    border-radius: 10px 10px 0 0;
  }

  // filter: drop-shadow( 2px 2px 2px );
}

#address-header {
  font-weight: bold;
  font-size: 120%;
  margin-bottom: 8px;
  color: rgb(75, 59, 40);
  border-bottom: 2px solid rgb(75, 59, 40);
}

#address {
  // min-width: 350px;
  color: rgb(75, 59, 40);
  z-index: 2;
  display: flex;
  flex-grow: 2;
  align-items: center;
  justify-content: center;
  order: 1;
  background-color: mintcream;
  padding: 20px 40px 20px 40px;
  color: black;
  font-size: 1.0rem;
  // filter: drop-shadow( 2px 2px 2px );
  border-radius: 0 10px 10px 0;

  @media screen and (min-width: 481px) and (max-width: 800px) {
    border-radius: 0 0 10px 10px;
  }

  @media screen and (max-width: 480px) {
    border-radius: 0;
  }

  @media screen and (max-width: 480px) {
    padding: 50px 10px;
    font-size: 1rem;
  }
}

#call-button {
  margin-top: 20px;
  padding: 10px 0;
  color: mintcream;
  height: 24px;
  line-height: 24px;
  background-color: rgba(239, 78, 76, 0.85);
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(212, 31, 28, 0.85);
  }
}

#map-link {
  text-decoration: none;

  border: 1px solid blue;
  &:hover {
    text-decoration: none;
  }
}

#opening-soon {
  color: red;
  font-weight: bold;
}

#order {
  cursor: pointer;
  // position: absolute;
  // z-index: 100;
  font-size: 1rem;
  // font-family: calibre, Helvetica Neue, helvetica, arial, sans-serif;
  color: mintcream;
  // color: white;
  border-radius: 5px;
  margin-top: 10px;
  height: 24px;
  line-height: 24px;
  background-color: rgba(239, 78, 76, 0.85);
  // box-shadow: 0px 0px 4px 1px honeydew;

  &:hover {
    background-color: rgba(212, 31, 28, 0.85);
  }

  @media screen and (max-width: 480px) {
    // bottom: 100px;
    // left: 20px;
    // right: 20px;
    padding: 12px 0;
    text-align: center;
  }

  @media screen and (min-width: 481px) and (max-width: 800px) {
    // top: 5%;
    // right: 3%;
    padding: 15px 15px;
  }

  @media screen and (min-width: 801px) {
    // top: 7%;
    // right: 4%;
    padding: 15px 15px;
  }

  .disabled {
    background-color: gray !important;
  }
}
</style>
