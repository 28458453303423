<script setup>
// import _ from 'lodash'
import { 
  // nextTick,
  computed,
  ref,
  reactive,
  // onMounted
} from 'vue'

import location from './location.vue'
import hours from './hours.vue'
const props = defineProps( [ 'bkmOffline', 'spgOffline'] )
// const emit = defineEmits( [] )

</script>

<template>
  <div id="locations-hours-container">
  <div id="inner-locations-hours-container">
    <hours />
    <div id="inner-locations-container">
      <location place="spg" :offline="props.bkmOffline" />
      <location place="bkm" :offline="props.spgOffline" />
    </div>
    <div id="inner-locations-container">
    </div>
  </div>
  </div>
</template>

<style lang="scss" scoped>

#locations-hours-container {
  display: flex;
  width: 100%;

  @media screen and ( max-width: 480px ) {
    margin: 0;

    border-top: 3px solid black;
    border-bottom: 3px solid black;
  }

  @media screen and ( min-width: 481px ) and ( max-width: 800px ) {
    margin: 50px;
  }


  @media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
    margin: 50px 0;
  }

  @media screen and ( min-width: 1201px ) {
    margin: 50px 0;

  }
}

#inner-locations-hours-container {
    position: relative;
  text-align: center;
  width: 100vw;
  display: flex;
  justify-content: center;

  
  @media screen and ( max-width: 480px ) {
    margin-top: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  @media screen and ( min-width: 481px ) and ( max-width: 800px ) {
    flex-direction: column;
    margin-top: 0;
  }


  @media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  @media screen and ( min-width: 1201px ) {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 50px;
  }
}

#inner-locations-container {
  // margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  @media screen and ( max-width: 480px ) and ( max-width: 800px ) {
    
  }

  @media screen and ( min-width: 481px ) and ( max-width: 1200px ) {
    margin: 0 auto;
  }

  @media screen and ( min-width: 1201px ) {
  }
}

</style>