
import { VueElement, createApp } from 'vue'
import { createPinia } from 'pinia'
import application from '@/components/layout/application.vue'

const pinia = createPinia()
const app = createApp( application )

app.use( pinia )

document.addEventListener('DOMContentLoaded', () => {
  document.getElementById("app").appendChild(document.createElement('app'))
  app.mount('app')
})