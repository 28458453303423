<script setup>
// import _ from 'lodash'
import {
  // nextTick,
  computed,
  ref,
  reactive,
  // onMounted
} from 'vue'

import locations from './locations.vue'
import catering from './catering.vue'
import story from './story.vue'

function viewMenu() {
  window.location.href = '/menu/menu.jpg'
}


const bkmOffline = ref( false )

const spgOffline = ref( false )

const onlineOrderStartedBKM = ref( false )
const onlineOrderStartedSPG = ref( false )

function onlineOrderStart( loc ) {
  if (loc == 'spg') {
    if ( spgOffline.value ) { return }
    if ( spgOffline.value ) { return }
    onlineOrderStartedSPG.value = true
    window.location.href =
    'https://direct.chownow.com/direct/29859/locations/44182'
  } else if (loc == 'bkm') {
    if ( bkmOffline.value ) { return }
    onlineOrderStartedBKM.value = true
    window.location.href =
    'https://www.toasttab.com/little-aris-japanese-kitchen-3439-burke-mill-rd-nc-27101'
  }
  
  setTimeout( () => {
    onlineOrderStartedBKM.value = false
    onlineOrderStartedSPG.value = false  
  }, 2000 )
}

</script>

<template>
  <div id="container">
    <div id="logo">
      <img
        id="logo-image"
        src="@/images/logo.png" />
    </div>
    <div id="hero">
      <img
        id="hero-image"
        src="@/images/teriyakichickensmall.jpg" />
    </div>

    <div
      id="order"
      @click="viewMenu">
      View Menu
    </div>
    <div id="slogan">Awesome hibachi with just a short wait.</div>

    <div id= "online-burke-mill" class="order-online-link" :class="{'offline-button': bkmOffline}" @click="onlineOrderStart('bkm')">
      <span v-if="!bkmOffline">
        <span v-if="!onlineOrderStartedBKM">
          Online&nbsp;Order&nbsp;-&nbsp;Winston
        </span>
        <span v-else class="ellipsis-anim"><span>.</span><span>.</span><span>.</span></span>
      </span>
      <span v-else>
        Winston - Online Order<br/>
        Currently Unavailable
      </span>
    </div>
    
    <div id="online-spring-garden" class="order-online-link" :class="{'offline-button': spgOffline}" @click="onlineOrderStart('spg')" >
      <span v-if="!bkmOffline">
        <span v-if="!onlineOrderStartedSPG">
          Online&nbsp;Order&nbsp;-&nbsp;Greensboro
        </span>
        <span v-else class="ellipsis-anim"><span>.</span><span>.</span><span>.</span></span>
      </span>
      <span v-else>
        Greensboro - Online Order<br/>
        Currently Unavailable
      </span>
    </div>
    <div id="locations-container">
      <div id="dining-container"><!-- sets background image --></div>
      <locations :bkmOffline="bkmOffline" :spgOffline="spgOffline" />
    </div>

    <!-- <div id="catering-container">
      <div id="chef-background"></div>
      <catering />
    </div> -->

    <div id="story-container">
      <!-- <story /> -->
    </div>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,700&display=swap');
</style>

<style lang="scss" scoped>

#container {
  box-sizing: border-box;
  font-family: calibre,Helvetica Neue,helvetica,arial,sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgba(236, 153, 64, 0.5);
}

#logo {
  position: absolute;
  left: 1%;
  top: 1%;
  width: 45%;
  z-index: 100;

}

#logo-image {
  width: 75%;
  max-width: 250px;
  min-width: 190px;
}

#hero {
  overflow: hidden;
  height: 100vh;
  width: auto;
}
#hero-image {
  position: relative;
  overflow-x: hidden;
  min-width: 700px;
  min-height: 100vh;
  max-width: 1520px;

  left: 50%;
  // top: -50px;
  transform: translateX( -50% );

  @media screen and ( max-width: 480px ) {
    // top: 0;
  }

  @media screen and ( min-width: 481px ) and ( max-width: 800px ) {
  }

  @media screen and ( min-width: 801px ) and ( max-width: 1000px ) {
  }

  @media screen and ( min-width: 1001px ) and ( max-width: 1200px ) {
  }

  @media screen and ( min-width: 1201px) {
    min-width: 100%;
    max-width: auto;
    // top: 0;
  }
}


.offline-button {
  background-color: gray !important;
  line-height: 16px !important;
  font-weight: 100 !important;
  font-size: 80% !important;

  @media screen and ( max-width: 800px ) {
    line-height: 30px !important;
  }
}
.order-online-link {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  height: 30px;

  font-size: 100%;
  color: mintcream;
  font-weight: bold;
  font-family: 'Roboto Flex', sans-serif;
  text-align: center;
  line-height: 30px;

  margin-top: 10px;
  padding: 15px 15px;
  color: mintcream;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px honeydew;
  
  background-color: rgba(239, 78, 76, 0.85);

  filter: drop-shadow( 2px 2px 1px black );
  
  @media screen and (max-width: 480px) {
  }
  
  @media screen and (min-width: 481px) and (max-width: 800px) {
    width: 85%;
  }
  
  @media screen and ( min-width: 801px ) {
    width: 220px;
  }



  &:hover {
    background-color: rgba(212, 31, 28, 0.85);
  }

  @media screen and (max-width: 480px) {
    // bottom: 100px;
    // left: 20px;
    // right: 20px;
    padding: 12px 0;
    text-align: center;
  }

  @media screen and (min-width: 481px) and (max-width: 800px) {
    // top: 5%;
    // right: 3%;
    padding: 15px 15px;
  }

  @media screen and ( min-width: 801px ) {
    // top: 7%;
    // right: 4%;
    padding: 15px 15px;

  }

  .disabled {
    background-color: gray !important;
  }

}

#online-burke-mill {
  @media screen and (max-width: 480px) {
    bottom: calc(10%);
    left: 20px;
    right: 20px;
    padding: 15px 0;
    text-align: center;
  }

  @media screen and (min-width: 481px) and (max-width: 800px) {
    bottom: calc(10%);
    left: 50%;  
    transform: translateX( -50% );
  }
  
  @media screen and ( min-width: 801px ) {
    top: 80%;
    left: 70%;  
    transform: translateX( -50% );
    
  }
}

#online-spring-garden {
  @media screen and (max-width: 480px) {
    bottom: calc( 10% + 80px );
    left: 20px;
    right: 20px;
    padding: 15px 0;
    text-align: center;
  }
  
  @media screen and (min-width: 481px) and (max-width: 800px) {
    bottom: calc( 10% + 80px );
    left: 50%;  
    transform: translateX( -50% );
  }
  
  @media screen and ( min-width: 801px ) {
    top: 80%;
    left: 30%;  
    transform: translateX( -50% ); 
  }
}

#order {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  font-size: 1.1rem;
  font-family: 'Roboto Flex', sans-serif;
  // font-family: calibre,Helvetica Neue,helvetica,arial,sans-serif;
  color: mintcream;
  // color: white;
  border-radius: 10px;
  background-color: rgba(239, 78, 76, 0.85);
  box-shadow: 0px 0px 4px 1px honeydew;
  filter: drop-shadow( 2px 2px 1px black );

  @media screen and (max-width: 480px) {
    bottom: calc( 50% + 50px );
    left: 20px;
    right: 20px;
    padding: 20px 0;
    text-align: center;
  }

  @media screen and (min-width: 481px) and (max-width: 800px) {
    top: 4%;
    right: 36px;
    padding: 15px 15px;
  }

  @media screen and ( min-width: 801px ) {
    top: 50px;
    right: 50px;
    padding: 15px 15px;

  }

  &:hover {
    background-color: rgba(212, 31, 28, 0.85);
  }
}


#slogan {
  cursor: pointer;
  position: absolute;
  z-index: 99;
  font-size: 300%;
  color: mintcream;
  font-weight: bold;
  font-family: 'Roboto Flex', sans-serif;
  top: 40%;
  left: 50%;
  padding: 15px 15px;
  text-align: center;
  transform: translateX( -50% );
  filter: drop-shadow( 10px 10px 10px black );
  
  @media screen and ( max-width: 350px ) {
    display: none;
  }

  @media screen and ( max-width: 480px ) {
    top: 0%;
    right: 2%;
    left: 50%;
    font-size: 200%;
    text-align: right;
    transform: translateX( 0% );
    filter: drop-shadow( 2px 1px 1px black );

  }

  @media screen and (min-width: 481px) and (max-width: 800px) {
    top: 25%;
    width: 80%;
  }

  @media screen and ( min-width: 801px ) {
    width: 500px;
  }
}

#dining-container {
  position: absolute;
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;

  background: url( '/images/diningroom.jpg');
  filter: brightness( 40% );

  @media screen and ( max-width: 480px ) {
    display: none;
    overflow: hidden;
  }

  @media screen and ( min-width: 481px ) and ( max-width: 800px ) {
    height: 100%;
    overflow: hidden;
  }

  @media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
    background-position: 0 25%;
    height: 100%;
    overflow: hidden;
  }

  @media screen and ( min-width: 1201px) {
    height: 100%;
    overflow: hidden;
    background-position: 0 40%;
  }

  @media screen and ( max-width: 480px ) { max-height: 900px;
    overflow: hidden;
    height: 800px;
  }

}

#locations-container {
  position: relative;

  width: 100%;
  // min-height: 100vh;
  overflow: visible;
  display: flex;
  justify-content: center;

  @media screen and ( max-width: 480px ) {

  }

  @media screen and ( min-width: 481px ) and ( max-width: 800px ) {
    min-height: 100vh;
  }

  @media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
    min-height: 100vh;
  }

  @media screen and ( min-width: 1201px) {
    height: 800px;
  }
}


#catering-container {
  position: relative;

  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

#chef-background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  min-width: 1200px;
  background: url( '/images/diningroom2.jpg');
  filter: brightness( 40% );
  height: auto;

  @media screen and ( max-width: 480px ) {
    display: none;
  }

    @media screen and ( min-width: 481px ) and ( max-width: 800px ) {
    background-position: 0 40%;
  }


  @media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
    background-position: 0 40%;
  }

  @media screen and ( min-width: 1201px ) {
  }
}


#story-container {
  position: relative;
  bottom: 0;

  @media screen and ( max-width: 480px ) {
  }

  @media screen and ( min-width: 481px ) and ( max-width: 800px ) {
  }


  @media screen and ( min-width: 801px ) and ( max-width: 1200px ) {
  }

  @media screen and ( min-width: 1201px ) {
  }
}
</style>

<style>
.ellipsis-anim span {
  font-size: 140%;
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
}

.ellipsis-anim span:nth-child(1) {
    -webkit-animation-delay: 0.0s;
    animation-delay: 0.0s;
}
.ellipsis-anim span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 0; }
}

</style>